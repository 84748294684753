import React, { Component } from "react";
import { CSSTransition } from 'react-transition-group';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   Redirect
// } from "react-router-dom";

import Interactions from "./interactions.js"
import Report from "./report.js"

import Api from "../api.js";

import Modal from './modal.js';

import AgeImg from "../img/questionnaire/age.svg";
import AlcoholImg from "../img/questionnaire/age.svg";
import BreastfeedingImg from "../img/questionnaire/breastfeeding.svg";
import DrivingImg from "../img/questionnaire/driving.svg";
import GenderImg from "../img/questionnaire/gender.svg";
import InteractionsImg from "../img/questionnaire/interactions.svg";
import PregnancyImg from "../img/questionnaire/pregnancy.svg";
import PregnancyPrediodImg from "../img/questionnaire/pregnancy_period.svg";
import ProcreationImg from "../img/questionnaire/procreation.svg";
import RenalFailureImg from "../img/questionnaire/renal_failure.svg";
import SportImg from "../img/questionnaire/sport.svg";
import RestrictedAreaImg from "../img/questionnaire/restricted_area.svg";
import WeightImg from "../img/questionnaire/weight.svg";


import AgeBg from "../img/questionnaire/bg_age.svg";
import AlcoholBg from "../img/questionnaire/bg_age.svg";
import BreastfeedingBg from "../img/questionnaire/bg_breastfeeding.svg";
import DrivingBg from "../img/questionnaire/bg_driving.svg";
import GenderBg from "../img/questionnaire/bg_gender.svg";
import InteractionsBg from "../img/questionnaire/bg_interactions.svg";
import PregnancyBg from "../img/questionnaire/bg_pregnancy.svg";
import PregnancyPrediodBg from "../img/questionnaire/bg_pregnancy_period.svg";
import ProcreationBg from "../img/questionnaire/bg_procreation.svg";
import RenalFailureBg from "../img/questionnaire/bg_renal_failure.svg";
import SportBg from "../img/questionnaire/bg_sport.svg";
import RestrictedAreaBg from "../img/questionnaire/bg_restricted_area.svg";
import WeightBg from "../img/questionnaire/bg_weight.svg";

const StepIllustrations = {
  'age': AgeImg,
  'alcohol': AlcoholImg,
  'breastfeeding': BreastfeedingImg,
  'driving': DrivingImg,
  'gender': GenderImg,
  'interactions': InteractionsImg,
  'pregnancy': PregnancyImg,
  'pregnancy_period': PregnancyPrediodImg,
  'procreation': ProcreationImg,
  'renal_failure': RenalFailureImg,
  'sport': SportImg,
  'restricted_area': RestrictedAreaImg,
  'weight': WeightImg
};

const StepBgs = {
  'age': AgeBg,
  'alcohol': AlcoholBg,
  'breastfeeding': BreastfeedingBg,
  'driving': DrivingBg,
  'gender': GenderBg,
  'interactions': InteractionsBg,
  'pregnancy': PregnancyBg,
  'pregnancy_period': PregnancyPrediodBg,
  'procreation': ProcreationBg,
  'renal_failure': RenalFailureBg,
  'sport': SportBg,
  'restricted_area': RestrictedAreaBg,
  'weight': WeightBg
};

const StepHelps = {
  'age': { title: "Pourquoi me demander mon âge ?", content: <p>Parce que</p>},
  'alcohol': { title: "En cela vous regarde ?", content: <p>Parce que</p>},
  'driving': { title: "En cela vous regarde ?", content: <p>Parce que</p>},
  'gender': { title: "Pourquoi me demander cela ?", content: <p>Parce que</p>},
  'interactions': { title: "Pourquoi connaitre mon traitement en cours ?", content: <p>Parce que</p>},
  'procreation': { title: "Quelle question indiscrète !", content: <p>Parce que</p>},
  'renal_failure': { title: "Pourquoi me demander cela ?", content: <p>Parce que</p>},
  'sport': { title: "Pourquoi me demander cela ?", content: <p>Parce que</p>},
  'restricted_area': { title: "Pourquoi me demander cela ?", content: <p>Parce que</p>},
  'weight': { title: "Pourquoi me demander mon poids ?", content: <p>Parce que</p>}
}

// const StepBgSVGs = {
//   'age': {},
//   'alcohol': {},
//   'breastfeeding': {},
//   'driving': {},
//   'gender': {},
//   'interactions': {},
//   'pregnancy': {},
//   'pregnancy_period': {},
//   'procreation': {},
//   'renal_failure': {},
//   'sport': {},
//   'restricted_area': {},
//   'weight': {}
// }

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeModals: {},
      drug: null,
      currentStepId: null,
      profile: {}
    };
  }

  stepInputs = {};

  componentWillMount() {
    this.selectDrug(this.props.match.params.cis);
  }

  selectDrug(cis) {
    console.log(cis);

    Api.get(`/drugs/${cis}.json`).then(res => {
      if (res.ok) {
        res.json().then(drug => {
          let steps = {};
          drug.questionnaire.steps.forEach(step => {
            steps[step.id] = step;
          });

          this.setState({
            drug: drug,
            steps: steps,
            currentStepId: drug.questionnaire.steps[0].id,
            results: null
          });
        });
      } else {
        alert("Erreur...");
      }
    });
  }

  handleKeyPress(e, step) {
    if (e.key === "Enter" && this.state.profile[step.field.id] !== null) {
      this.nextStep();
    }
  }

  setStep(stepId) {
    let state = { currentStepId: stepId };

    if (this.state.currentStepId) {
      let steps = this.state.steps;
      steps[this.state.currentStepId].isDone = true;
      state.steps = steps;
    }

    this.setState(state, () => {
      if (this.stepInputs[stepId]) {
        // Temporary for mobile rendering
        // this.stepInputs[stepId].focus();
      }
    });
  }

  setField(field, value, switchToNext) {
    let profile = this.state.profile;
    profile[field] = value;
    this.setState({ profile: profile }, () => {
      if (switchToNext) {
        this.nextStep();
      }
    });
  }

  nextStep(currentId) {
    if (!currentId) {
      currentId = this.state.currentStepId;
    }

    let steps = this.state.steps;
    
    if (!steps[currentId].next) {
      this.submitProfile();
    } else {
      let nextStepId = steps[currentId].next;
      if (steps[nextStepId].conditions) {
        for (let field in steps[nextStepId].conditions) {
          let valid = true;
          if (
            this.state.profile[field] !== null &&
            this.state.profile[field] === steps[nextStepId].conditions[field]
          ) {
          } else {
            valid = false;
          }

          if (valid) {
            this.setStep(nextStepId);
          } else {
            this.nextStep(nextStepId);
          }
        }
      } else {
        this.setStep(nextStepId);
      }
    }
  }

  renderBooleanField(step) {
    return (
      <div className="boolean-field">
        <button 
            id={`step-input-${step.id}-no`}
            type="button" 
            className="btn btn-lg btn-secondary"
            onClick={e => this.setField(step.field.id, true, true)}>Non</button>
        <button 
          id={`step-input-${step.id}-yes`}
          type="button" 
          className="btn btn-lg btn-primary"
          onClick={e => this.setField(step.field.id, true, true)}>Oui</button>
      </div>
    );
  }

  handleInputSubmit(step) {
    if (this.state.profile[step.field.id]) {
        this.nextStep();
    }
  }

  renderTextField(step) {
    if (step.field.choices) {
      let content = step.field.choices.map(choice => {
        return (
            <button 
                id={`step-input-${step.id}-${choice.value}`}
                key={`step-input-${step.id}-${choice.value}`}
                type="button" 
                className="btn btn-lg btn-secondary"
                onClick={e => this.setField(step.field.id, choice.value, true)}>
                {choice.label}
            </button>
        );
      });
      return <div className="choices-field">{content}</div>;
    } else {
      return (
        <div className="text-field form-group">
            <div className="input-group">
                <input
                    name={`step-input-${step.id}`}
                    type="text"
                    className="form-control"
                    placeholder={step.label}
                    id={`step-input-${step.id}`}
                    onChange={e => this.setField(step.field.id, e.target.value)}
                    onKeyPress={e => this.handleKeyPress(e, step)}
                    ref={(input) => { this.stepInputs[step.id] = input; }} /> 
                <div className="input-group-append">
                    <button type="button" className="btn btn-outline-secondary"
                        onClick={e => this.handleInputSubmit(step) }>OK</button>
                </div>
            </div>
        </div>);
    }
  }

  renderNumericField(step) {
    return (
        <div className="text-field form-group">
            <div className="input-group">
              <input
                  name={`step-input-${step.id}`}
                  type="number"
                  className="form-control"
                  placeholder={step.label}
                  id={`step-input-${step.id}`}
                  onChange={e =>
                    this.setField(
                      step.field.id,
                      step.field.type === "float"
                        ? parseFloat(e.target.value)
                        : parseInt(e.target.value)
                    )
                  }
                  onKeyPress={e => this.handleKeyPress(e, step)}
                  ref={(input) => { this.stepInputs[step.id] = input; }}
                  /> 
              <div className="input-group-prepend">
                <span className="input-group-text">{step.field.unit}</span>
              </div>
              <div className="input-group-append">
                <button type="button" className="btn btn-outline-secondary"
                    onClick={e => this.handleInputSubmit(step) }>OK</button>
              </div>
            </div>
    </div>);
  }

  setModalState(id, state) {
    let activeModals = this.state.activeModals;
    activeModals[id] = state;
    this.setState({ activeModals: activeModals });
  }

  onInteractionsStepDone(step, ids) {
    this.setField(step.field.id, ids, true)
  }

  renderStepInput(step) {
    if (step.type === "boolean") return step.input ? "oui" : "non";
    else if (step.unit) {
      return `${step.input} ${step.unit}`;
    }

    return step.input;
  }

  showStepHelp(e, stepId) {
    e.preventDefault();
    this.setModalState(`help-${stepId}`, true);
  }

  onStepHelpClose(stepId) {
    this.setModalState(`help-${stepId}`, false);
  }

  _renderStep(step, isInner) {
    let stepInput = null;
    let fieldType = step.field.type;

    if (fieldType === "integer" || fieldType === "float") {
      stepInput = this.renderNumericField(step);
    } else if (fieldType === "boolean") {
      stepInput = this.renderBooleanField(step);
    } else {
      stepInput = this.renderTextField(step);
    }
    
    let stepClass = isInner ? '' : `step ${(this.state.currentStepId === step.id ? 'active' : '')} ${this.state.steps[step.id].isDone ? 'done' : ''}`;

    return (
      <section className={stepClass}>
        <header>
          <div className="step-question" key={`question-${step.id}`}>
            <span className="inner-content">{step.question}</span>
          </div>
        </header>
        <fieldset>
          <div className="step-illustration" key={`illustration-${step.id}`}>
              <div className="bg"><img src={StepBgs[step.id]} alt="" /></div>
              <img className="illustration" src={StepIllustrations[step.id]} alt="" />
          </div>
        </fieldset>
        <footer>
            <div className="step-input" key={`input-${step.id}`}>
              {stepInput}
              {
                !isInner && StepHelps[step.id] && 
                <a href={null} className="help" onClick={(e) => this.showStepHelp(e, step.id)}>Pourquoi cette question ?</a>
              }
            </div>
        </footer>
          {
            !isInner && StepHelps[step.id] && this.state.activeModals[`help-${step.id}`] &&
            <Modal
              key={`help-${step.id}`}
              title={StepHelps[step.id].title}
              onClose={() => this.onStepHelpClose(step.id)}>
              {StepHelps[step.id].content}
            </Modal>
          }
      </section>
    );
  }

  _renderWithPreliminary(step) {
    let stepClass = `${(this.state.currentStepId === step.id ? 'active' : '')} ${this.state.steps[step.id].isDone ? 'done' : ''}`;
    let stepModalKey = step.id;
    let stepHelpKey = `help-${step.id}`;

    return (
      <section className={`step ${stepClass}`}>
        <header>
          <div className="step-question" key={`question-${step.id}-preliminary`}>{step.preliminary}</div>
        </header>
        <fieldset>
          <div className="step-illustration" key={`illustration-${step.id}`}>
            <div className="bg"><img src={StepBgs[step.id]} alt="" /></div>
            <img className="illustration" src={StepIllustrations[step.id]} alt="" />
          </div>
        </fieldset>
        <footer>
          <div className="step-input" key={`input-${step.id}`}>
            <div className="boolean-field" key={`boolean-field-${step.id}-preliminary`}>
              <button 
                id={`step-input-${step.id}-no`}
                type="button" 
                className="btn btn-lg btn-secondary"
                onClick={e => this.setField(step.field.id, null, true)}>Non</button>
              <button 
                id={`step-input-${step.id}-yes`}
                type="button" 
                className="btn btn-lg btn-primary"
                onClick={e => this.setModalState(stepModalKey, true)}>Oui</button>
            </div>
            {
              StepHelps[step.id] && 
              <a href={null} className="help" onClick={(e) => this.showStepHelp(e, step.id)}>Pourquoi cette question ?</a>
            }
          </div>
        </footer>
        <CSSTransition
          in={this.state.activeModals[stepModalKey] == true}
          timeout={300}
          classNames="modal"
          unmountOnExit={true}>
          {
            step.id === 'interactions' 
            ? <Interactions 
                key={stepModalKey}
                drug={this.state.drug} 
                step={step} 
                onSelectionValidate={(ids) => this.onInteractionsStepDone(step, ids)} />
            : <Modal customClass="inner-step" key={stepModalKey} closeBtn={null}>{this._renderStep(step, true)}</Modal>
          }
        </CSSTransition>
        {
          StepHelps[step.id] && 
          <CSSTransition
            in={this.state.activeModals[stepHelpKey] == true}
            timeout={300}
            classNames="modal"
            unmountOnExit={true}>
            <Modal
              title={StepHelps[step.id].title}
              onClose={() => this.onStepHelpClose(step.id)}
              key={`help-${step.id}`}>
              {StepHelps[step.id].content}
            </Modal>
          </CSSTransition>
        }
      </section>
    );
  }

  renderStep(step) {
    if (step.preliminary) {
      return this._renderWithPreliminary(step);
    }

    return this._renderStep(step)
  }

  submitProfile() {
    console.log(this.state.profile);

    Api.post(`/drugs/${this.state.drug.id}/validate`, this.state.profile).then(
      res => {
        if (res.ok) {
          res
            .json()
            .then(results =>
              this.setState({ mode: "results", results: results })
            );
        } else {
          alert("errors...");
        }
      }
    );
  }

  render() {
    if (!this.state.drug) {
      return null;
    }

    let questionnaire = this.state.drug.questionnaire.steps.map(step => {
      return this.renderStep(step);
    });

    return (
      <div>
        <form id="questionnaire"
            autoComplete="off"
            onSubmit={e => e.preventDefault()}
            >
            { questionnaire.map(step => step) }
        </form>

        <CSSTransition
          in={this.state.mode === 'results' && this.state.results !== null}
          timeout={300}
          classNames="modal"
          unmountOnExit={true}>
          <Report drug={this.state.drug} profile={this.state.profile} results={this.state.results} /> 
        </CSSTransition>
      </div>
    );
  }
}

export default Questionnaire;
