import React, { Component } from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Home from "./components/home.js";
import Questionnaire from "./components/questionnaire.js";
import About from "./components/about.js";

import "./stylesheets/bootstrap.css";
import "./stylesheets/App.css";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <BrowserRouter>
          <Route
            render={({ location }) => (
              <TransitionGroup>

              <CSSTransition key={location.key} classNames="modal" timeout={300}>
                <Switch location={location}>
                    <Route path="/medicaments/:cis" component={Questionnaire}/>
                    <Route path="/a-propos" component={About}/>
                    <Route path="/" component={Home}/>
                </Switch>
              </CSSTransition>
              </TransitionGroup>
            )}
          />
        </BrowserRouter>
    );
  }
}

export default App;
