import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


import Logo from "../img/logo.svg";

import SearchIcon from "../img/search.svg";
import CancelIcon from "../img/cancel.svg";

import Api from "../api.js";

const TRIGGER_SEARCH_DELAY = 400;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearching: false,
      searchQuery: "",
      searchIntervalId: null,
      searchResults: null,
      selectedDrug: null
    };

    this.onSearchInputChange = this.onSearchInputChange.bind(this);
  }

  onSearchInputChange(e) {
    if (this.state.searchIntervalId) {
      clearInterval(this.state.searchIntervalId);
    }

    this.setState({
      searchQuery: e.target.value,
      searchIntervalId: setInterval(() => {
        this.triggerSearch();
      }, TRIGGER_SEARCH_DELAY)
    });
  }

  setIsResultsMode(value) {
    this.setState({ isResultsMode: value });
  } 

  handleSearchSubmit(e) {
    e.preventDefault();
    this.triggerSearch();
  }

  triggerSearch() {
    clearInterval(this.state.searchIntervalId);
    this.setState({ isSearching: true }, () => {
      Api.get(`/drugs.json?q=${this.state.searchQuery}`).then(res => {
        if (res.ok) {
          res.json().then(drugs =>
            {
              this.setState({
                isSearching: false,
                searchResults: drugs
              });

              this.setIsResultsMode(true);
            }
          );
        } else {
          alert("errors...");
        }
      });
    });
  }

  renderSearchResult(drug) {
    let name = drug.name.split(", ");
    let extractedShortName = name[0];
    let extractedForm = name.length > 1 ? name[1] : null;
    
    return (
      <li key={`result-${drug.id}`}>
        <Link
          to={`/medicaments/${drug.cis}`}
          className="drug-info"
        >
            <div className="drug-name">
                <span
                className="short-name"
                dangerouslySetInnerHTML={{
                    __html: this.highlightSearchInput(extractedShortName)
                }}
                />
                {extractedForm && (
                <span className="packaging">{extractedForm}</span>
                )}
            </div>
            <span className="composition">
                {drug.overview.active_ingredients}
            </span>
        </Link>
      </li>
    );
  }

  highlightSearchInput(text) {
    let regex = new RegExp(`(${this.state.searchQuery})`, "i");
    return text.replace(regex, "<strong>$1</strong>");
  }

  cancel() {
    this.setState({ 
        isResultsMode: false,
        isSearching: false,
        searchQuery: "",
        searchIntervalId: null,
        searchResults: null
    });

    this.searchInput.focus();
  }

  render() {
    let searchResults = null;
    if (this.state.searchResults) {
      searchResults = <ol id="search-results">{this.state.searchResults.map(drug => this.renderSearchResult(drug))}</ol>;
    }

    return (
      <section id="home" className={ this.state.isResultsMode ? 'results-mode' : ''}>
        <header>
          <img src={Logo} alt="" />
        </header>
        <form autoComplete="off" onSubmit={(e) => this.handleSearchSubmit(e)}>
          <fieldset>
            <legend>Puis-je prendre ce medicament ?</legend>
            <div className="form-group">
              <div className="input-group search-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom du médicament"
                  onChange={this.onSearchInputChange}
                  autoFocus
                  ref={(input) => { this.searchInput = input; }}
                  value={this.state.searchQuery} /> 
                <div className="input-group-append">
                    {
                        this.state.isResultsMode
                        ? <button type="button" className="btn btn-outline-secondary" onClick={(e) => this.cancel()}><img src={CancelIcon} alt="" /></button>
                        : <button type="button" className="btn btn-outline-secondary"><img src={SearchIcon} alt="" /></button>
                    }
                    
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        { searchResults }
        <footer className="fixed">
          <div>
            <span>2018</span>
            <Link to="/a-propos">A propos</Link>
          </div>
          <div>
            <a href="https://twitter.com/medicamentum_io" target="_blank"><i class="fab fa-twitter"></i></a>
            <a href="mailto:info@beyowi.com" target="_blank"><i className="far fa-envelope"></i></a>
          </div>
        </footer>
      </section>
    );
  }
}

export default Home;
