import React, { Component } from 'react';
import StepReport from './step.js'; 

const SUMMARY_STATUSES = {
    CONTRAINDICATION: 'contraindication',
    WARNING: 'warning',
    POSSIBLE_NO_INFO: 'possible_no_info',
    POSSIBLE: 'possible',
  };

const AllowedTypeStatuses = [SUMMARY_STATUSES.POSSIBLE_NO_INFO, SUMMARY_STATUSES.POSSIBLE];

class InteractionsReport extends StepReport {
    constructor(props) {
        super(props);
    }

    renderPanel() {
        let step = this.props.step;

        if (!AllowedTypeStatuses.includes(step.id)) {
            return null;
        }

        let drugInteractions = [];

        for (let cis in step.result.content) {
            let drug = step.result.content[cis];
            drugInteractions.push(<li key={`interaction-${drug.id}`}><span className={`status ${drug.interactions && drug.interactions.length ? 'contraindication' : 'possible'}`}></span> {drug.short_name}</li>    )
        }

        return <div className={`step-results-details collapse ${this.props.status ? 'show' : ''}`} id="interactions-results-panel">
            <p><small>Présence d'interactions</small></p>
            <ul>
                {drugInteractions}
            </ul>
        </div>;
    }
}

export default InteractionsReport;