import React, { Component } from 'react';
import ChevronDown from "../../img/chevron-down.svg";

import AgeIcon from "../../img/icons/age.svg";
import BreastfeedingIcon from "../../img/icons/breastfeeding.svg";
import DrivingIcon from "../../img/icons/driving.svg";
import GenderIcon from "../../img/icons/gender.svg";
import InteractionsIcon from "../../img/icons/interactions.svg";
import PregnancyIcon from "../../img/icons/pregnancy.svg";
import ProcreationIcon from "../../img/icons/procreation.svg";
import RenalFailureIcon from "../../img/icons/renal_failure.svg";
import SportIcon from "../../img/icons/sport.svg";
import AlcoholIcon from "../../img/icons/alcohol.svg";
import RestrictedAreaIcon from "../../img/icons/restricted_area.svg";
import WeightIcon from "../../img/icons/weight.svg";

const StepIcons = {
    'age': AgeIcon,
    'alcohol': AlcoholIcon,
    'breastfeeding': BreastfeedingIcon,
    'driving': DrivingIcon,
    'gender': GenderIcon,
    'interactions': InteractionsIcon,
    'pregnancy': PregnancyIcon,
    'procreation': ProcreationIcon,
    'renal_failure': RenalFailureIcon,
    'sport': SportIcon,
    'restricted_area': RestrictedAreaIcon,
    'weight': WeightIcon
};

class StepReport extends Component {
    renderPanel() {
        let step = this.props.step;

        if (!step.result.content) {
            return null;
        }

        return (
            <div 
                className={`step-results-details collapse ${this.props.status ? 'show' : ''}`} 
                id={`${step.id}-results-panel`}>
                <p><small>{ step.result.content }</small></p>
            </div>
        );
    }

    render() {
        let step = this.props.step;

        let linkProps = {
            'data-toggle': "collapse", 'data-target': `#${step.id}-results-panel`, 
            role: "button", 'aria-expanded': "false",
            'aria-controls': `${step.id}-results-panel`
        }

        let detailsPanel = this.renderPanel();

        return (
            <li key={`step-results-${step.id}`}>
                <a href="#" 
                    className="step-item" 
                    role="button" 
                    onClick={(e) => this.props.toggleStepPanel(e, step.id)}
                    {...linkProps}>
                    <span className="step-img">
                        <img src={StepIcons[step.id]} alt={step.label} />
                    </span>
                    <span className="step-inner">
                        <span className="step-name">
                            <strong>{step.label}</strong>
                            <span className={`status ${step.result.status.id}`}></span>
                        </span>
                        <span className="chevron">
                            { detailsPanel && <img src={ChevronDown} /> }
                        </span>
                    </span>
                </a>
                { detailsPanel }
            </li>
        );
    }
}

export default StepReport;