import React, { Component } from 'react';
import Modal from "./modal.js";

import SearchIcon from "../img/search.svg";
import PlusCorailIcon from "../img/plus-corail.svg";
import CancelIcon from "../img/cancel.svg";

import Api from "../api.js";

const TRIGGER_SEARCH_DELAY = 400;

class Interactions extends Component {
    constructor(props) {
        super(props);

        this.state = {
          isSearching: false,
          searchQuery: "",
          searchIntervalId: null,
          searchResults: null,
          selectedDrug: null,
          selection: []
        };
    
        this.onSearchInputChange = this.onSearchInputChange.bind(this);
    }

    addToSelection(e, drug) {
      e.preventDefault();
      let selection = this.state.selection;
      selection.push(drug);
      this.setState({ selection: selection, searchQuery: "", searchResults: null }, () => this.setIsResultsMode(false));
  
      this.searchInput.focus();
    }

    onSearchInputChange(e) {
      if (this.state.searchIntervalId) {
        clearInterval(this.state.searchIntervalId);
      }
  
      this.setState({
        searchQuery: e.target.value,
        searchIntervalId: setInterval(() => {
          this.triggerSearch();
        }, TRIGGER_SEARCH_DELAY)
      });
    }
  
    setIsResultsMode(value) {
      this.setState({ isResultsMode: value });
    } 
  
    handleSearchSubmit(e) {
      e.preventDefault();
      this.triggerSearch();
    }
  
    triggerSearch() {
      clearInterval(this.state.searchIntervalId);
      this.setState({ isSearching: true }, () => {
        Api.get(`/drugs.json?q=${this.state.searchQuery}`).then(res => {
          if (res.ok) {
            res.json().then(drugs =>
              {
                this.setState({
                  isSearching: false,
                  searchResults: drugs
                });
  
                this.setIsResultsMode(true);
              }
            );
          } else {
            alert("errors...");
          }
        });
      });
    }
  
    renderSearchResult(drug) {
      let name = drug.name.split(", ");
      let extractedShortName = name[0];
      let extractedForm = name.length > 1 ? name[1] : null;
  
      return (
        <li key={`result-${drug.id}`}>
          <a href="#" className="drug-info" onClick={(e) => this.addToSelection(e, drug)}>
              <div className="drug-name">
                  <span
                  className="short-name"
                  dangerouslySetInnerHTML={{
                      __html: this.highlightSearchInput(extractedShortName)
                  }}
                  />
                  {extractedForm && (
                  <span className="packaging">{extractedForm}</span>
                  )}
              </div>
              <span className="composition">
                  {drug.overview.active_ingredients}
              </span>
          </a>
        </li>
      );
    }
  
    highlightSearchInput(text) {
      let regex = new RegExp(`(${this.state.searchQuery})`, "i");
      return text.replace(regex, "<strong>$1</strong>");
    }

    cancel() {
      this.setState({ 
          isResultsMode: false,
          isSearching: false,
          searchQuery: "",
          searchIntervalId: null,
          searchResults: null
      });
  
      this.searchInput.focus();
    }

    onSelectionValidate() {
        if (this.props.onSelectionValidate) {
            this.props.onSelectionValidate(this.state.selection.map((drug) => drug.id));
        }
    }

    render() {
        let searchResults = null;
        if (this.state.searchResults) {
          searchResults = <ol id="search-results">{this.state.searchResults.map(drug => this.renderSearchResult(drug))}</ol>;
        }

        let selection = null;
        if (this.state.selection && this.state.selection.length) {
          selection = <ol id="selection">{this.state.selection.map(drug => {
            let name = drug.name.split(", ");
            let extractedShortName = name[0];
            return <li key={`selection-${drug.cis}`}><span>{ extractedShortName }</span><button><img src={CancelIcon} alt="" /></button></li>
          })}</ol>;
        }

        return (
          <Modal 
            id="interactions" 
            customClass={`${ this.state.isResultsMode ? 'results-mode' : ''}`}
            closeBtn={null}>
            <header>
                <span>Entrez le nom du ou des médicament(s)</span>
            </header>
            { selection }
            <form autoComplete="off" onSubmit={(e) => this.handleSearchSubmit(e)}>
                <fieldset>
                    <div className="form-group">
                        <div className={`input-group search-input ${this.state.selection.length > 0 ? 'additionnal' : ''}`}>
                            {
                                !this.state.isResultsMode && this.state.selection.length > 0 && 
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><img src={PlusCorailIcon} alt="" /></span>
                                </div>
                            }
                            <input
                            type="text"
                            className="form-control"
                            placeholder={this.state.selection.length === 0 ? "Nom du médicament" : "Ajouter un médicament"}
                            onChange={this.onSearchInputChange}
                            autoFocus
                            ref={(input) => { this.searchInput = input; }}
                            value={this.state.searchQuery} />
                            {
                                this.state.isResultsMode
                                ? <button type="button" className="btn btn-outline-secondary" onClick={(e) => this.cancel()}><img src={CancelIcon} alt="" /></button>
                                : (this.state.selection.length === 0 && <div className="input-group-append"><button type="button" className="btn btn-outline-secondary"><img src={SearchIcon} alt="" /></button></div>)
                            }
                        </div>
                    </div>
                </fieldset>
            </form>
            { searchResults }
            <footer>
              <button type="button" className="btn btn-lg btn-secondary" onClick={(e) => this.onSelectionValidate()}>Valider</button>
            </footer>
          </Modal>
        );
    }
}

export default Interactions;