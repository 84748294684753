import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Modal from './modal.js';

class About extends Component {
  render() {
    let link = <Link to="/" className="btn btn-lg btn-secondary btn-sticky">Retour à l'accueil</Link>;

    return (
      <Modal 
        title="A propos"
        isActive={true} 
        closeBtn={link}
        footerClass="fixed">
        <div id="about">
          <p>Medicamentum est un projet issu de la plateforme <strong>medicat.io</strong>, <a href="https://www.etalab.gouv.fr/dataconnexions-5-palmares-retour-en-images-sur-la-finale" target="_blank">lauréat du concours Dataconnexions #5 dans la catégorie Grand Public en 2015</a>. Ce concours était organisé par la mission <a href="https://www.etalab.gouv.fr" target="_blank"><strong>Etalab</strong></a>, attachée au Secrétariat général pour la modernisation de l’action publique, pour promouvoir la politique d’ouverture et de partage des données publiques en récompensant les meilleures réutilisations les valorisant.</p>
          
          <div id="etalab-pitch" style={{ textAlign: "center", margin: "20px 0 20px" }}>
            <iframe frameBorder="0" width="100%" src="//www.dailymotion.com/embed/video/x2hi02y" allowFullScreen=""></iframe>
            <br />
            <a href="http://www.dailymotion.com/video/x2hi02y_finale-dataconnexions-5-medicat-io-laureat-dataconnexions-data2c_tech" target="_blank">Présentation du projet medicat.io lors de la finale du concours Dataconexions #5</a> (<i>par <a href="http://www.dailymotion.com/etalab" target="_blank">etalab</a></i>)
          </div> 

          <p>Initié par un ancien co-auteur, et actuellement porté par le studio <a href="http://beyowi.com/" target="_blank"><strong>Beyowi</strong></a>, le nouveau projet se veut en être une refonte à travers la recherche d'une nouvelle expérience de navigation. Il conserve son objectif d'améliorer l'<strong>accessibilité</strong> et la <strong>facilité de découverte</strong> des informations sur les médicaments actuellement en vente en France, tout en garantissant une <strong>fiabilité</strong> basée sur des données structurées publiquement mises à dispositions. Il vise également à être un support à de nouveaux services basés sur la réutilisation de futures données mises à disposition par les institutions.</p>

          <p>Il continue de ne se subsituer en aucun cas aux médecins et aux pharmaciens, et vise avant tout à sensibiliser le consommateur afin de favoriser les échanges avec les professionnels de la santé.</p>
          
          <h2>Sources de données</h2>

          <ul id="data-sources">
            <li>
              <a href="http://base-donnees-publique.medicaments.gouv.fr/telechargement.php" target="_blank"><strong>Base de données publique des médicaments</strong> - Ministère des Affaires sociales, de la Santé et des Droits des femmes</a>
            </li>
            <li>
              <a href="http://base-donnees-publique.medicaments.gouv.fr/telechargement.php" target="_blank"><strong>Base du Thésorimed</strong></a>
            </li>
          </ul>
          
          <h2>Mentions légales</h2>

          <p>medicamentum.fr fournit à titre gratuit des informations dont l’utilisateur reconnait être le seul responsable de l’emploi qu’il en fera. En aucun cas, la responsabilité de medicamentum.fr ne saurait être engagée et aucune conséquence ne saurait lui être attribuée dans tous les cas y compris si une information présente sur le site présentait un caractère erroné. L'ensemble des informations accessibles via cette plateforme sont fournies en l'état. medicamentum.fr ne donne aucune garantie explicite ou implicite et n'assume aucune responsabilité relative à l'utilisation de ces informations.</p>
          
          <h2>Contact</h2>
          
          <p style={{ textAlign: "center" }}>Pour tout renseignement, remarque ou suggestion, écrivez-nous à <a href="mailto:info@beyowi.com">info[at]beyowi.com</a></p>
        </div>
      </Modal>
    );
  }
}

export default About;