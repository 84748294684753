import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";

import Modal from "./modal.js";

import AgeReport from "./reports/age.js";
import BreastfeedingReport from "./reports/breastfeeding.js";
import DrivingReport from "./reports/driving.js";
import GenderReport from "./reports/gender.js";
import InteractionsReport from "./reports/interactions.js";
import PregnancyReport from "./reports/pregnancy.js";
import ProcreationReport from "./reports/procreation.js";
import RenalFailureReport from "./reports/renal_failure.js";
import SportReport from "./reports/sport.js";
import AlcoholReport from "./reports/alcohol.js";
import RestrictedAreaReport from "./reports/restricted_area.js";
import WeightReport from "./reports/weight.js";

import PossibleIcon from "../img/icons/possible.svg";
import WarningIcon from "../img/icons/warning.svg";
import ContraIndicationIcon from "../img/icons/contraindication.svg";



const StepReports = {
    'age': AgeReport,
    'alcohol': AlcoholReport,
    'breastfeeding': BreastfeedingReport,
    'driving': DrivingReport,
    'gender': GenderReport,
    'interactions': InteractionsReport,
    'pregnancy': PregnancyReport,
    'procreation': ProcreationReport,
    'renal_failure': RenalFailureReport,
    'sport': SportReport,
    'restricted_area': RestrictedAreaReport,
    'weight': WeightReport
};

const StatusIcons = {
    'possible': PossibleIcon,
    'possible_no_info': PossibleIcon,
    'warning': WarningIcon,
    'contraindication': ContraIndicationIcon,
}

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepPanels: {}
        }

        this.toggleStepPanel = this.toggleStepPanel.bind(this);
    }

    toggleStepPanel(e, id) {
        e.preventDefault();
        let stepPanels = this.state.stepPanels;
        stepPanels[id] = !stepPanels[id];
        this.setState({ stepPanels: stepPanels });
    }

    render() {
        let stepsResults = this.props.results.steps.map((step) => {
            const ReportTag = StepReports[step.id];
            return <ReportTag 
                key={`report-${step.id}`} 
                drug={this.state.drug} step={step} 
                status={this.state.stepPanels[step.id]}
                toggleStepPanel={this.toggleStepPanel} />;
        });

        let closeBtn = <Link to="/" className="btn btn-lg btn-secondary btn-sticky">Nouvelle recherche</Link>;
        let header = <div className={this.props.results.status.id}>
            <h2>{ this.props.drug.short_name }</h2>
            <p className="global-status">
                <img src={StatusIcons[this.props.results.status.id]} />
                <strong>{this.props.results.status.label}</strong>
            </p>
        </div>;

        return (
            <Modal 
                id="results"
                title={header}
                footerClass="fixed"
                closeBtn={closeBtn}>
                <ul className="step-results">
                    { stepsResults }
                </ul>
            </Modal>
        );
    }
}

export default Report;