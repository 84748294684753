import React, { Component } from 'react';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    let closeBtn = this.props.closeBtn !== null ? (this.props.closeBtn || <button type="button" className="btn btn-lg btn-sticky btn-secondary" onClick={this.close}>Fermer</button>) : null;
    let customClass = this.props.customClass || '';

    return (
        <section className={`modal ${customClass}`} id={this.props.id || ''}>
          { 
            this.props.title && 
            <header>
              {this.props.title}
            </header>
          }
          {this.props.children} 
          { 
            closeBtn &&
            <footer className={`${this.props.footerClass || ''}`}>
              {closeBtn}
            </footer>
          }
        </section>
    );
  }
}

export default Modal;