const base64 = require('base-64');

const hostname = window && window.location && window.location.hostname;

let apiUrl;

if(hostname === 'dev.medicamentum.fr') {
  apiUrl = 'http://dev.medicamentum.fr/api';
} else {
  apiUrl = 'http://localhost:3000/api';
}
// apiUrl = 'http://dev.medicamentum.fr/api';

const API_URL = apiUrl;

console.log('Using ' + API_URL);

let headers = { 
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Token token=WwEsPpOCYMsyPsmmvKBqQDOaEJ4t'
};

class Api {
    static DefaultNewDocumentId = 'new';

    static documents = [];

    static get(url, params = {}) {
        return fetch(API_URL + url, {
            method: 'GET',
            headers: headers,
            mode: 'cors'
        })
    }

    static post(url, params = {}) {
        return fetch(API_URL + url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        })
    }

    static put(url, params = {}) {
        return fetch(API_URL + url, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(params)
        })
    }

    static delete(url, params = {}) {
        return fetch(API_URL + url, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(params)
        })
    }
}

export default Api;